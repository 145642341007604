import React from 'react';
import { Player } from 'video-react';
import "../../styles/video.scss";
import * as styles from './video.module.scss';

const Video = ({ temp = {} }) => {
  const fl = {float: 'left',paddingRight: '2%', left: 0};
  const fr = {float: 'right',paddingLeft: '2%', right: 0};
  return (
    <div className={styles.questionsContent}>
      {
        temp.content.map((item, i) => (
          <div key={`${item}${i}`} className={`videoWrap ${styles.container} clearfix`}>
            <div className={styles.photo} style={i%2 != 1? {float: 'left',paddingRight: '2%'}: {float: 'right',paddingLeft: '2%'}}>
              <Player
                poster={temp.poster[i]}
                playsInline
                src={temp.video[i]}
              />
            </div>
            <div className={styles.content} style={i%2 != 1? fr: fl}>
              <div dangerouslySetInnerHTML={{ __html: item }} ></div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Video;
